<script>
    import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
    import Fa from 'svelte-fa'

    export let title = ''
    export let description = ''
    export let href
</script>

<a {href} class="w-100 text-decoration-none">
    <div class="list-group-item list-group-item-action border-0 shadow">
        <div class="row align-items-center">
            <div class="col-10 text-start">
                <strong class="fw-semibold">{title}</strong>
                <br />
                <small class="text-secondary">{description}</small>
            </div>
            <div class="col-2 text-center">
                <Fa icon={faAngleRight} />
            </div>
        </div>
    </div>
</a>

<style lang="scss">
    .list-group-item {
        font-size: 95%;
        background-color: rgba(var(--bs-white-rgb));
        padding: 0.9em;
        margin-bottom: 0.7rem;
    }

    .list-group-item:hover {
        background-color: #f8f9fa;
    }

    a {
        color: inherit;
    }
</style>

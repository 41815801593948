<script>
    import { fetching, payment, variants } from '$lib/stores'
    import { goto } from '$app/navigation'
    import { _ } from 'svelte-i18n'
    import PanelButton from '$lib/Components/PanelButton.svelte'
    import Cancel from '$lib/Components/Payment/Buttons/Cancel.svelte'
    import { page } from '$app/stores'

    $: paymentFetched = $fetching.payment?.fetched
    const searchParams = $page.url.searchParams

    $: {
        if (paymentFetched) {
            if ($variants.length === 1) {
                switch ($variants[0]) {
                    case 'variant1':
                        goto(`/call?${searchParams.toString()}`, { replaceState: true })
                        break
                    case 'variant2':
                        goto(`/ip?${searchParams.toString()}`, { replaceState: true })
                        break
                    case 'variant3':
                        goto(`/wallet?${searchParams.toString()}`, { replaceState: true })
                        break
                    case 'variant4':
                        goto(`/external?${searchParams.toString()}`, { replaceState: true })
                        break
                    default:
                }
                // If there are no available variants, customerNumber is null,
                // we can ask the user for his number
            } else if ($variants.length === 0 && $payment.customerNumber === null) {
                goto(`/confirmnumber?${searchParams.toString()}`, { replaceState: true })
            }
        }
    }
</script>

<h1 class="mb-4 pt-3">
    {$_('payment.paymentmethod.title.select_method')}
</h1>

<div class="mb-5">
    {#if $variants.includes('variant1')}
        <PanelButton
            title={$_('payment.paymentmethod.phone_bill')}
            description={$_('payment.paymentmethod.phone_bill.secondary')}
            href={`/call?${searchParams.toString()}`}
        />
    {/if}

    {#if $variants.includes('variant2')}
        <PanelButton
            title={$_('payment.paymentmethod.phone_bill')}
            description={$_('payment.paymentmethod.ip.secondary')}
            href={`/ip?${searchParams.toString()}`}
        />
    {/if}

    {#if $variants.includes('variant4')}
        <PanelButton
            title={$_('payment.paymentmethod.phone_bill')}
            description={$_('payment.paymentmethod.ip.secondary')}
            href={`/external?${searchParams.toString()}`}
        />
    {/if}

    {#if $variants.includes('variant3')}
        <PanelButton
            title={$_('payment.paymentmethod.wallet')}
            description={$_('payment.paymentmethod.wallet.secondary')}
            href={`/wallet?${searchParams.toString()}`}
        />
    {/if}
</div>

<Cancel />
